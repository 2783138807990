.button-collapse{
  color: #eee;
  left: 0;
  margin-right: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  top: 0;
  &:hover{
  color: #ccc;
  }
  &:focus{
    background-color: transparent;
  }
}

.side-nav{
width: 80% !important;
  @extend header;
  label.slide-out-label{
    color: #bdd354;
    &.active{
      color: #50697d !important;
    }
  }
  .sidenav{
   list-style: none;
    li{
       a{
        color: #eee !important;
        border-bottom: 1px solid #eee;

        &:hover{
          background-color: rgba(#eee , 0.2);
          text-decoration: none;
        }
      }
      &:last-child{
        a{
          border-bottom: none;
        }
      }
    }
  }
  .sliderCnt{
    display: flex;
    justify-content: space-between;
    width: 100%;
    .slider{
      margin: 0 1%;
      flex-grow: 1;
    }
  }
}
