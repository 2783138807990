.picker{
  z-index: 20000 !important;
  bottom: 0;
  &__frame{
    display: flex;
    justify-content: center;
  }
  &__wrap{
    width: 300px;
  }
  &__box{
    display: flex;
    flex-direction: column;
  }
  &__day{
    &--infocus{
      padding: 0.65rem 0;
    }
  }
  &__header{
    margin-bottom: 15px;
  }
}

