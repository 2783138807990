.socialLinks {
  position: relative;
 .bookmark{
   cursor: pointer;
   white-space: nowrap;
   font-size: $font-size-big-1;
   color: $base-blue;
   @media (min-width: 1200px){
     font-size: 14px;
   }
   @media (min-width: 1300px){
     font-size: $font-size-big-1;
   }
 }
}
header{
  .socialLinks{
    @include breakpoint(xl) {
      &:before{
        content: '';
        width: 1px;
        background-color: #cb8c5e;
        height: 100px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -50px;
      }
    }
  }
}
.slBlock {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .fa{
    font-size: 15px;
    text-decoration: none;
    border-bottom: none;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: #fff;
    margin: 1px;
  }
  &.mod{
    .fa{
      height: 45px;
      width: 45px;
      &:before{
        font-size: 30px;
      }
    }
    .slMM{
      @include sprite($mail-mod);
    }
  }
}
header, .modal-content{
  .slBlock{
    .fa-paper-plane{
      background-color: #55acef;
    }
  }

}
.fa-vk{
  background-color: #5a7da3;
}
.fa-facebook{
  background-color: #395795;
}
.fa-twitter{
  background-color: #55acef;
}

.fa-odnoklassniki{
  background-color: #f2730c;
}
.fa-whatsapp{
  background-color: #6ad766;
}
.fa-instagram{
  background-color: color("light-blue", "darken-2");
}
.fa-pencil{
  color: #000 !important;
  background-color: #01a2d9;
}
.slMM{
  display: inline-block;
  border-radius: 3px;
 @include sprite($mail);
  margin: 1px;
}








