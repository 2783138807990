header{
  padding-top: 10px;
  @include breakpoint(xl) {
    padding-top: 0;
  }
  margin-bottom: 20px;
  box-shadow: -1px 6px 2px -3px rgba(0,0,0,0.2);
  background: rgb(36,43,51); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(36,43,51,1) 0%, rgba(60,70,82,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(36,43,51,1) 0%,rgba(60,70,82,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(36,43,51,1) 0%,rgba(60,70,82,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#242b33', endColorstr='#3c4652',GradientType=0 ); /* IE6-9 */

  .block-logo{
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint(md) {
      align-items: center;
      justify-content: center;
    }
    display: flex;
    box-sizing: content-box;
    position: relative;
    width: 100%;
    @include breakpoint(xl) {
      width: auto;
    }
    .logo {
      @include sprite($logo);
      text-indent: - 99999px;
      text-align: center;
    }
    &:after{
      content: '';
      width: 1px;
      background-color: #cb8c5e;
      height: 100px;
      display: none;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -50px;
      @include breakpoint(xl) {
        display: block;
      }
    }

    button{
      top: 0;
    }
  }
  .container-fluid{
      flex-wrap: wrap;
      @include breakpoint(xl) {
        flex-wrap: nowrap;
      }
      min-height: 100px;
    .searchForm, .block-logo, .socialLinks{
      @include breakpoint(md) {
        padding-left: 1%;
        padding-right: 1%;
      }
      @include breakpoint(lg) {
        padding-left: 1.6%;
        padding-right: 1.6%;
      }
    }
    }
  .slBlock:not(.fixed-action-btn){
    padding: 5px 0;
    width: 120px;

  }
  .fa-heart{
    position: relative;
    right: 0;
    top: 0;
    width: 26px;
    height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:before{
      color: $base-orange;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }
    span{
      color: #fff;
      font-size: 13px;
     // position: absolute;
      top: 4px;
      text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
      position: relative;
      z-index: 10;
    }
  }
  .buttons{
    width: 100%;
    @include breakpoint(md) {
      width: auto;
    }
  }
 .searchForm{
   .central-block{
     @include breakpoint(md) {
       width: 50%;
     }

   }

 }
}



