#citySelect,
#stateSelect,
#stationSelect {
  white-space: nowrap;
}

.cards .navigation .navigate {
  font-size: 17px;
}
.cards .navigate .pagerNavActive {
  display: inline-block;
  margin: 0 8px 0 0;
  padding: 1px 2px;
  color: #000;
}
.loader {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.clear {
  clear: both;
}
section .saunaInfo2 {
  padding: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 6px #ccc, 0px 0px 6px #ccc, 0px 0px 6px #ccc;
  -moz-box-shadow: 0px 0px 6px #ccc, 0px 0px 6px #ccc, 0px 0px 6px #ccc;
  box-shadow: 0px 0px 6px #ccc, 0px 0px 6px #ccc, 0px 0px 6px #ccc;
}
.saunaInfo li .callbackForm {
  margin-left: -20px;
}

.saunaInfo li li .callbackForm {
  margin-left: -40px;
}

.saunaInfo li li li .callbackForm {
  margin-left: -60px;
}

.saunaInfo li li li li .callbackForm {
  margin-left: -80px;
}

.saunaInfo li li li li li .callbackForm {
  margin-left: -100px;
}

.saunaInfo .feedback {
  list-style-type: none;
}

.saunaInfo .feedback .message {
  font-size: 12px;
  line-height: 1.5em;
}



.saunaInfo .feedbackForm,
.saunaInfo .callbackForm {
  color: #252c34;
  font-size: 12px;
}

.saunaInfo .callbackForm {
  width: 640px;
}

.saunaInfo .feedbackForm .small,
.saunaInfo .callbackForm .small {
  float: left;
  width: 190px;
}

.saunaInfo .feedbackForm .big,
.saunaInfo .callbackForm .big {
  float: right;
  width: 445px;
}

.saunaInfo .feedbackForm label,
.saunaInfo .callbackForm label {
  margin-bottom: 7px;
  line-height: 19px;
  display: block;
}




.simpleText h1 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
}

.simpleText h2 {
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.simpleText h3 {
}

.simpleText p {
  margin-bottom: 15px;
  line-height: 1.62;
}

.simpleText .form {
  width: 500px;
  margin: auto;
}

.form LABEL {
  display: block;
  width: 500px;
  height: 30px;
  clear: both;
  line-height: 22px;
}

.form LABEL INPUT,
.form LABEL SELECT,
.form LABEL textarea {
  float: right;
  width: 350px;
}

.form .inline {
  padding-left: 150px;
  width: 350px;
  height: 25px;
  line-height: 14px;
}

.form .inline INPUT,
.form .inline SELECT {
  float: none;
  width: auto;
}

.form .textarea {
  height: 200px;
}

.form .textarea textarea {
  height: 188px;
  width: 348px;
}

.form LABEL SELECT {
  width: 354px;
}

.simpleText .form .addPhoto,
.simpleText .form .addNumber {
  cursor: pointer;
  font-style: italic;
  margin-bottom: 20px;
  border-bottom: 1px dotted #444;
}

.form .addBTN {
  width: 150px;
  margin-left: 175px;
  margin-top: 20px;
}

.form .fotoTitle,
.form .numbersTitle {
  font-weight: bold;
  border-bottom: 1px solid #444;
  margin-bottom: 5px;
}

.priceTable {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.priceTable tr td {
  vertical-align: middle;
  border: 1px solid #ccc;
  text-align: center;
  background-color: #ebf0f3;
}

.priceTable tr td.selected {
  background-color: #d1deea;
}

.priceTable tr td:nth-child(1) {
  background-color: #fff;
}

.priceTable tr:nth-child(1) td {
  background-color: #fff;
}

#nabivalka {
  display: none;
  width: 640px;
  margin: auto;
}

#nabivalka label {
  display: block;
  width: 100%;
  height: 24px;
}

#nabivalka label.step {
  width: 90%;
  padding-left: 10%;
}

#nabivalka label input {
  width: 50%;
  float: right;
}

#nabivalka label input[type="checkbox"] {
  float: none;
  width: 20px;
}

#nabivalka .group {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  margin-bottom: 20px;
}

#nabivalka .groupFull {
  width: 630px;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  margin-bottom: 20px;
}

#nabivalka strong {
  display: block;
  margin-bottom: 10px;
}

#nabivalka span {
  display: block;
  margin-bottom: 5px;
}



.opaciti0 {
  opacity: 0;
  font-weight: 100;
}

.tempAraySaunes, .tempAray2Saunes {
  display: none;
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.fb-comments > span, .fb-comments iframe {
  width: 100% !important;
}

.fb_iframe_widget, #vk_comments, #vk_comments iframe {
  width: 100% !important;
}

.uiTextareaAutogrow {
  height: 50px !important;
}

.LastSaun {
  padding-left: 0px;
}

.LastSaun i {
  display: block;
  height: auto;
  margin-bottom: 10px;
  padding-right: 2px;
  padding-left: 10px;
  padding-bottom: 5px;
  position: relative;
  font-style: normal;
}

.LastSaun i:hover {
  cursor: pointer;
  background: #c6c4c4;
}

.LastSaun > i div {
  font-size: 12px;
  color: #50697d;
}

.LastSaun > div.LastSaunRightP, .LastSaun > div.LastSaunLeftP {
  display: none;
}

.LastSaun > i > h2 {
  padding-left: 10px;
}

.LastSaun > i table {
  display: none;
}

.LastSaun .rating {
  float: left;
}

.LastSaun .price {
  color: #9b9b9b;
  font-size: 80%;
}

.LastSaun .parametr {
  color: #9b9b9b;
  font-size: 80%;
}

.LastSaun .comments {
  float: left;
  background-image: url('/site/images/commentsBG.png');
  width: 27px;
  height: 16px;
  padding-top: 1px;
  padding-bottom: 5px;
  font-size: 10px;
  color: #000;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
  margin-left: 20px;
  margin-right: 20px;
}

.LastSaun .LastSaunLeftImag {
  border-right: 1px dashed #9b9b9b;
}

.LastSaun .LastSaunLeftP {
  display: inline-block;
  width: 200px;
}

.LastSaun .LastSaunRightP {
  display: block;
  position: absolute;
  left: 220px;
  right: 0%;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  font-weight: normal;
  color: #323030;
  font-size: 13px;
  padding-right: 10px;
}

.LastSaun .LastSaunRightP p {
  margin-bottom: 5px;
  text-indent: 10px;
}

.LastSaun .LastSaunRightP h2 {
  font-size: 18px;
  font-weight: normal;
  color: #f07904;
  margin-bottom: 3px;
  padding-left: 20px;
  margin-top: 10px;
  color: #665b59;
}

.LastSaun hr {
  width: 186px;
  border-top: 1px solid #9b9b9b;
  margin-left: auto;
  margin-right: auto;
}

.LastSaun .category, .feedback .category > span {
  font-size: 12px;
  font-weight: normal;
  color: #f07904;
  margin-bottom: 3px;
}

.history {
  font-weight: normal;
  font-size: 32px;
  font-family: $base-font;
}

.history + .dotted-bottom {
  padding: 0 0 15px 0;
  margin-bottom: 15px;
}

.dotted-bottom {
  padding: 20px 0;
  margin-bottom: 15px;
  border-bottom: 1px dotted #CCC;
  height: auto;
  overflow: hidden;
}

.load_next {
  width: 100px;
  margin: 10px auto;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: #73718e;
}

.load_next:hover {
  cursor: pointer;
  background: #e4e2e2;
  text-shadow: 1px 0px 2px #fff;
}

.likeMeVisit {
  float: right;
  padding-right: 5px;
  overflow: hidden;
}

#vk_like, #vk_like iframe {
  width: 80px !important;
}

.fb-like {
  width: 100px;
}

.load_next {
  display: none;
}

#map3 {
  font: 13px Arial, Helvetica, sans-serif !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: left;
}

.display-none {
  display: none;
}

.saunaInfo #map2, .saunaInfo #map3 {
  width: 99.5%;
  height: 300px;
  border: 2px solid #cfcfcf;
  position: relative;
  z-index: 10;
  background-color: #fff;
  overflow: hidden;
  text-align: left;
}




.padding5 {
  padding: 5px;
}


.smal_center {
  text-align: center;
}


.LastSaun .LastSaunRightP .grad {
  position: absolute;
  bottom: 0px;
  height: 13px;
  left: 0px;
  width: 100%;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNGY0ZjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(244, 244, 244, 0)), color-stop(100%, rgba(244, 244, 244, 1)));
  background: -webkit-linear-gradient(top, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 1) 100%);
  background: -o-linear-gradient(top, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 1) 100%);
  background: -ms-linear-gradient(top, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 1) 100%);
  background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%, rgba(244, 244, 244, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00f4f4f4', endColorstr='#f4f4f4', GradientType=0);
}


.paddingT50 {
  padding-top: 50px;
}

.marginT20 {
  margin-top: 20px;
}


.bottomView .price {
  font-weight: bold;
  font-size: 120%;
}



.ListView span.phone {
  font-weight: bold;
  color: #97ab38;
}

hr.hrmarginL {
  margin-left: -7px;
  width: 196px;
}

.ramblerTop100A {
  margin: 20px auto;
  max-width: 250px;
}

.text .block h2.f-size-16 {
  font-size: 14px;
}

