/*!
 * jQuery Brazzers Carousel v1.0.0 (http://webdesign-master.ru)
 * Copyright 2015 WebDesign Master.
 */
 
.brazzers-daddy:after {
	content: "";
	display: table;
	clear: both;
}
.brazzers-daddy {
	position: relative;
}
.brazzers-daddy img {
	width: 100%;
	position: relative;
	display: none;
	top: 0;
	left: 0;
	margin-bottom: 10px;
}
.brazzers-daddy img:first-child {
	display: block;
}
.tmb-wrap {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.tmb-wrap .tmb-wrap-table {
	display: table;
	height: 100%;
	width: 100%;
}
.tmb-wrap-table div {
	display: table-cell;
	transition: all .3s ease;
	border-bottom: 5px solid transparent;
	padding-top: 10px;
	-webkit-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease;
}
.tmb-wrap-table div.active {
	border-color: #FBB254;
}
.tmb-wrap-table div:first-child:last-child {
	border-color: transparent;
}