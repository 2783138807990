.rating{

  a{
    display: inline-block;
    &.votePlus {
      @include sprite($like-up);
    }
    &.voteMinus {
      @include sprite($like-down);
    }
  }
  &-body{}
}
.response-rating{
  span{
    cursor: pointer;
  }
}
.finger-rating{
  label{
    &.like-up{
      @include sprite($like-up);
    }
    &.like-down{
      @include sprite($like-down);
    }
  }
}
