.pagination{
  padding-left: 0;
  list-style-type: none;
  margin: 0;
  li{
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;
    a{
      color: #5b758a;
      display: inline-block;
      font-size: 1.2rem;
      padding: 0 10px;
      line-height: 30px;
      &:hover{
        text-decoration: none;
      }
    }
    &.disabled{
      a{
        cursor: default;
        color: #999;
      }
    }
    &.active{
      background-color: #5b758a;
      a{
        color: #fff;
      }
    }
  }
}



