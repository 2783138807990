body{

  font-family: $base-font;
  color: $base-font-color;
  font-size: $base-font-size;
  background: rgb(190,190,190); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(190,190,190,1) 0%, rgba(218,218,218,1) 12%, rgba(238,238,238,1) 29%, rgba(238,238,238,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(190,190,190,1) 0%,rgba(218,218,218,1) 12%,rgba(238,238,238,1) 29%,rgba(238,238,238,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(190,190,190,1) 0%,rgba(218,218,218,1) 12%,rgba(238,238,238,1) 29%,rgba(238,238,238,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bebebe', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
}
select, input, textarea, button {
  font: 99% sans-serif;

}
ul, ol {
  margin-left: 1.8em;
}
.responsive-img{
  width: 100% !important;
  height: auto !important;
}

hr {
  margin: 10px 0;
  padding: 0px;
  height: 1px;
  border: none;
  color: $base-border;
  background-color: $base-border;

}
.link-to-mainPage{
  &:hover{
    text-decoration: none;
    color: #fff;
  }
}
.bordered{
  border-top: 1px solid $base-border;
  border-bottom: 1px solid $base-border;
  padding: 10px 0;
  margin: 10px 0;
}
.clearFilter{
  cursor: pointer;
}
.w-310{
  display: block;
  width: 100%;
  @include breakpoint(sm) {
    width: 310px !important;
  }
}
.fixBody{
  height: 100%;
  position: fixed;
}
.flex-grow-1_5{
  flex-grow: 1.5;
}
.smile{
  @include sprite($smile);
}
.fancybox-caption{
 bottom: auto;
  top: 0;
z-index: 0 !important;
}

.fancybox-caption{
  text-align: center;
}
.table{
  caption{
    caption-side: top;
  }
}
