.sort{
  margin-left: -8px;
  .custom-select{
    &-panel{
      z-index: 20;
    }
    &-option{
      cursor: pointer;
      &.has-focus{
       background-color: rgba(#999, 0.8);
        color: #000;
      }
      &:before{
        display: none;
      }
    }
    &-container{
      &.is-open{
        .custom-select-panel{
          max-height: none;
        }
      }

    }
    &-opener{
      letter-spacing: -0.04em;
      &:focus{
        outline: none;
      }
    }
  }
  &:focus{
    outline: none;
  }
}
.clearFilter{
  &.custom-select-opener{
    width: auto;
    margin-right: -8px;
  }
}

