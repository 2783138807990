@media all and (orientation: portrait) {
}

@media all and (orientation: landscape) {
}

@media screen and (max-device-width: 480px) {
}

@media print {
  * {
    background: transparent !important;
    color: #444 !important;
    text-shadow: none !important;
  }

  a, a:visited {
    color: #444 !important;
    text-decoration: underline;
  }

  a:after {
    content: " (" attr(href) ")";
  }

  abbr:after {
    content: " (" attr(title) ")";
  }

  .ir a:after {
    content: "";
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  @page {
    margin: 0.5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}
