.columns{
  @include breakpoint(md) {
    column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-gap: 2em;
    -webkit-column-gap: 2em;
    -moz-column-gap: 2em;
  }
  h1{
    font-size: 20px;
  }
  object, iframe{
    width: 100%;
    max-width: 100%;

  }
}
.number{
  object, iframe{
    width: 100%;
    max-width: 100%;

  }
}



