.photos{
  .small{
    margin: 0 -3%;
    padding: 0 1.5%;
    a.photo{
      width: 30%;
      margin: 0 1.4%;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}
