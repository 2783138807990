// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$comments-name: 'comments';
$comments-x: 160px;
$comments-y: 139px;
$comments-offset-x: -160px;
$comments-offset-y: -139px;
$comments-width: 50px;
$comments-height: 31px;
$comments-total-width: 244px;
$comments-total-height: 207px;
$comments-image: '../images/sprite.png';
$comments: (160px, 139px, -160px, -139px, 50px, 31px, 244px, 207px, '../images/sprite.png', 'comments', );
$ears-next1-mob-name: 'ears-next1-mob';
$ears-next1-mob-x: 120px;
$ears-next1-mob-y: 44px;
$ears-next1-mob-offset-x: -120px;
$ears-next1-mob-offset-y: -44px;
$ears-next1-mob-width: 40px;
$ears-next1-mob-height: 131px;
$ears-next1-mob-total-width: 244px;
$ears-next1-mob-total-height: 207px;
$ears-next1-mob-image: '../images/sprite.png';
$ears-next1-mob: (120px, 44px, -120px, -44px, 40px, 131px, 244px, 207px, '../images/sprite.png', 'ears-next1-mob', );
$ears-next2-mob-name: 'ears-next2-mob';
$ears-next2-mob-x: 0px;
$ears-next2-mob-y: 44px;
$ears-next2-mob-offset-x: 0px;
$ears-next2-mob-offset-y: -44px;
$ears-next2-mob-width: 40px;
$ears-next2-mob-height: 131px;
$ears-next2-mob-total-width: 244px;
$ears-next2-mob-total-height: 207px;
$ears-next2-mob-image: '../images/sprite.png';
$ears-next2-mob: (0px, 44px, 0px, -44px, 40px, 131px, 244px, 207px, '../images/sprite.png', 'ears-next2-mob', );
$ears-prev1-mob-name: 'ears-prev1-mob';
$ears-prev1-mob-x: 40px;
$ears-prev1-mob-y: 44px;
$ears-prev1-mob-offset-x: -40px;
$ears-prev1-mob-offset-y: -44px;
$ears-prev1-mob-width: 40px;
$ears-prev1-mob-height: 131px;
$ears-prev1-mob-total-width: 244px;
$ears-prev1-mob-total-height: 207px;
$ears-prev1-mob-image: '../images/sprite.png';
$ears-prev1-mob: (40px, 44px, -40px, -44px, 40px, 131px, 244px, 207px, '../images/sprite.png', 'ears-prev1-mob', );
$ears-prev2-mob-name: 'ears-prev2-mob';
$ears-prev2-mob-x: 80px;
$ears-prev2-mob-y: 44px;
$ears-prev2-mob-offset-x: -80px;
$ears-prev2-mob-offset-y: -44px;
$ears-prev2-mob-width: 40px;
$ears-prev2-mob-height: 131px;
$ears-prev2-mob-total-width: 244px;
$ears-prev2-mob-total-height: 207px;
$ears-prev2-mob-image: '../images/sprite.png';
$ears-prev2-mob: (80px, 44px, -80px, -44px, 40px, 131px, 244px, 207px, '../images/sprite.png', 'ears-prev2-mob', );
$like-down-name: 'like-down';
$like-down-x: 219px;
$like-down-y: 0px;
$like-down-offset-x: -219px;
$like-down-offset-y: 0px;
$like-down-width: 25px;
$like-down-height: 32px;
$like-down-total-width: 244px;
$like-down-total-height: 207px;
$like-down-image: '../images/sprite.png';
$like-down: (219px, 0px, -219px, 0px, 25px, 32px, 244px, 207px, '../images/sprite.png', 'like-down', );
$like-up-name: 'like-up';
$like-up-x: 219px;
$like-up-y: 32px;
$like-up-offset-x: -219px;
$like-up-offset-y: -32px;
$like-up-width: 25px;
$like-up-height: 32px;
$like-up-total-width: 244px;
$like-up-total-height: 207px;
$like-up-image: '../images/sprite.png';
$like-up: (219px, 32px, -219px, -32px, 25px, 32px, 244px, 207px, '../images/sprite.png', 'like-up', );
$like-name: 'like';
$like-x: 0px;
$like-y: 175px;
$like-offset-x: 0px;
$like-offset-y: -175px;
$like-width: 93px;
$like-height: 32px;
$like-total-width: 244px;
$like-total-height: 207px;
$like-image: '../images/sprite.png';
$like: (0px, 175px, 0px, -175px, 93px, 32px, 244px, 207px, '../images/sprite.png', 'like', );
$logo-name: 'logo';
$logo-x: 0px;
$logo-y: 0px;
$logo-offset-x: 0px;
$logo-offset-y: 0px;
$logo-width: 219px;
$logo-height: 44px;
$logo-total-width: 244px;
$logo-total-height: 207px;
$logo-image: '../images/sprite.png';
$logo: (0px, 0px, 0px, 0px, 219px, 44px, 244px, 207px, '../images/sprite.png', 'logo', );
$mail-mod-name: 'mail-mod';
$mail-mod-x: 160px;
$mail-mod-y: 94px;
$mail-mod-offset-x: -160px;
$mail-mod-offset-y: -94px;
$mail-mod-width: 45px;
$mail-mod-height: 45px;
$mail-mod-total-width: 244px;
$mail-mod-total-height: 207px;
$mail-mod-image: '../images/sprite.png';
$mail-mod: (160px, 94px, -160px, -94px, 45px, 45px, 244px, 207px, '../images/sprite.png', 'mail-mod', );
$mail-name: 'mail';
$mail-x: 219px;
$mail-y: 64px;
$mail-offset-x: -219px;
$mail-offset-y: -64px;
$mail-width: 25px;
$mail-height: 25px;
$mail-total-width: 244px;
$mail-total-height: 207px;
$mail-image: '../images/sprite.png';
$mail: (219px, 64px, -219px, -64px, 25px, 25px, 244px, 207px, '../images/sprite.png', 'mail', );
$smile-name: 'smile';
$smile-x: 160px;
$smile-y: 44px;
$smile-offset-x: -160px;
$smile-offset-y: -44px;
$smile-width: 50px;
$smile-height: 50px;
$smile-total-width: 244px;
$smile-total-height: 207px;
$smile-image: '../images/sprite.png';
$smile: (160px, 44px, -160px, -44px, 50px, 50px, 244px, 207px, '../images/sprite.png', 'smile', );
$ym-name: 'ym';
$ym-x: 93px;
$ym-y: 175px;
$ym-offset-x: -93px;
$ym-offset-y: -175px;
$ym-width: 100px;
$ym-height: 22px;
$ym-total-width: 244px;
$ym-total-height: 207px;
$ym-image: '../images/sprite.png';
$ym: (93px, 175px, -93px, -175px, 100px, 22px, 244px, 207px, '../images/sprite.png', 'ym', );
$spritesheet-width: 244px;
$spritesheet-height: 207px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($comments, $ears-next1-mob, $ears-next2-mob, $ears-prev1-mob, $ears-prev2-mob, $like-down, $like-up, $like, $logo, $mail-mod, $mail, $smile, $ym, );
$spritesheet: (244px, 207px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
