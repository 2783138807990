@media (min-width: 1370px){
  .banner-xl-flex{
    display: flex !important;
  }
}
@media (min-width: 1060px){
  .banner-lg-flex{
    display: flex !important;
  }
}
@media (min-width: 800px){
  .banner{
    margin: 0;
  }
}

.banner{
  margin: 0 2%;
  img {
    width: 100%;
    height: 100%;
  }
  p{
    margin-bottom: 0;
  }
}
