.sitemap{
  list-style-type: none;
  &-city{
    li{
      list-style: none;
    }
  }
  &-inner{
    position: relative;
    &:before{
      content: '';
      border-left: 4px solid $base-blue;
      padding-right: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
}
