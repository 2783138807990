.searchDD:not(.city){
  position: absolute;
  height: auto;
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 8px #000;
  -moz-box-shadow: 0px 0px 8px #000;
  box-shadow: 0px 0px 8px #000;
  display: none;
  z-index: 101;
  left: 0;
  right: 0;

  &>div {
    display: inline-block;
    width: 170px;
    height: 24px;
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-bottom: 5px;
    @include breakpoint(sm) {
      margin-bottom: 0;
    }

    a {
      font-size: 1.3rem;
      color: #6f849f;
      @include breakpoint(sm) {
        font-size: 0.9rem;
      }
      text-decoration: none;
      border-bottom: 1px dotted #6f849f;
      white-space: nowrap;
      &:hover {
        color: #666;
        border-bottom: 1px dotted #666;
      }
    }
  }
  &:empty{
    padding: 0;
    visibility: hidden;
  }

}


