// ==========================================================================
// Materialize variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Badges
//  3. Buttons
//  4. Cards
//  5. Carousel
//  6. Collapsible
//  7. Chips
//  8. Date + Time Picker
//  9. Dropdown
//  10. Fonts
//  11. Forms
//  12. Global
//  13. Grid
//  14. Navigation Bar
//  15. Side Navigation
//  16. Photo Slider
//  17. Spinners | Loaders
//  18. Tabs
//  19. Tables
//  20. Toasts
//  21. Typography
//  22. Footer
//  23. Flow Text
//  24. Collections
//  25. Progress Bar

$materialize-red: (
  "base":       #e51c23,
  "lighten-5":  #fdeaeb,
  "lighten-4":  #f8c1c3,
  "lighten-3":  #f3989b,
  "lighten-2":  #ee6e73,
  "lighten-1":  #ea454b,
  "darken-1":   #d0181e,
  "darken-2":   #b9151b,
  "darken-3":   #a21318,
  "darken-4":   #8b1014,
);

$red: (
  "base":       #F44336,
  "lighten-5":  #FFEBEE,
  "lighten-4":  #FFCDD2,
  "lighten-3":  #EF9A9A,
  "lighten-2":  #E57373,
  "lighten-1":  #EF5350,
  "darken-1":   #E53935,
  "darken-2":   #D32F2F,
  "darken-3":   #C62828,
  "darken-4":   #B71C1C,
  "accent-1":    #FF8A80,
  "accent-2":    #FF5252,
  "accent-3":    #FF1744,
  "accent-4":    #D50000
);

$pink: (
  "base":       #e91e63,
  "lighten-5":  #fce4ec,
  "lighten-4":  #f8bbd0,
  "lighten-3":  #f48fb1,
  "lighten-2":  #f06292,
  "lighten-1":  #ec407a,
  "darken-1":   #d81b60,
  "darken-2":   #c2185b,
  "darken-3":   #ad1457,
  "darken-4":   #880e4f,
  "accent-1":    #ff80ab,
  "accent-2":    #ff4081,
  "accent-3":    #f50057,
  "accent-4":    #c51162
);

$purple: (
  "base":       #9c27b0,
  "lighten-5":  #f3e5f5,
  "lighten-4":  #e1bee7,
  "lighten-3":  #ce93d8,
  "lighten-2":  #ba68c8,
  "lighten-1":  #ab47bc,
  "darken-1":   #8e24aa,
  "darken-2":   #7b1fa2,
  "darken-3":   #6a1b9a,
  "darken-4":   #4a148c,
  "accent-1":    #ea80fc,
  "accent-2":    #e040fb,
  "accent-3":    #d500f9,
  "accent-4":    #aa00ff
);

$deep-purple: (
  "base":       #673ab7,
  "lighten-5":  #ede7f6,
  "lighten-4":  #d1c4e9,
  "lighten-3":  #b39ddb,
  "lighten-2":  #9575cd,
  "lighten-1":  #7e57c2,
  "darken-1":   #5e35b1,
  "darken-2":   #512da8,
  "darken-3":   #4527a0,
  "darken-4":   #311b92,
  "accent-1":    #b388ff,
  "accent-2":    #7c4dff,
  "accent-3":    #651fff,
  "accent-4":    #6200ea
);

$indigo: (
  "base":       #3f51b5,
  "lighten-5":  #e8eaf6,
  "lighten-4":  #c5cae9,
  "lighten-3":  #9fa8da,
  "lighten-2":  #7986cb,
  "lighten-1":  #5c6bc0,
  "darken-1":   #3949ab,
  "darken-2":   #303f9f,
  "darken-3":   #283593,
  "darken-4": #1a237e,
  "accent-1":    #8c9eff,
  "accent-2":    #536dfe,
  "accent-3":    #3d5afe,
  "accent-4":    #304ffe
);

$blue: (
  "base":       #2196F3,
  "lighten-5":  #E3F2FD,
  "lighten-4":  #BBDEFB,
  "lighten-3":  #90CAF9,
  "lighten-2":  #64B5F6,
  "lighten-1":  #42A5F5,
  "darken-1":   #1E88E5,
  "darken-2":   #1976D2,
  "darken-3":   #1565C0,
  "darken-4":   #0D47A1,
  "accent-1":    #82B1FF,
  "accent-2":    #448AFF,
  "accent-3":    #2979FF,
  "accent-4":    #2962FF
);

$light-blue: (
  "base":       #03a9f4,
  "lighten-5":  #e1f5fe,
  "lighten-4":  #b3e5fc,
  "lighten-3":  #81d4fa,
  "lighten-2":  #4fc3f7,
  "lighten-1":  #29b6f6,
  "darken-1":   #039be5,
  "darken-2":   #0288d1,
  "darken-3":   #0277bd,
  "darken-4":   #01579b,
  "accent-1":    #80d8ff,
  "accent-2":    #40c4ff,
  "accent-3":    #00b0ff,
  "accent-4":    #0091ea
);

$cyan: (
  "base":       #00bcd4,
  "lighten-5":  #e0f7fa,
  "lighten-4":  #b2ebf2,
  "lighten-3":  #80deea,
  "lighten-2":  #4dd0e1,
  "lighten-1":  #26c6da,
  "darken-1":   #00acc1,
  "darken-2":   #0097a7,
  "darken-3":   #00838f,
  "darken-4":   #006064,
  "accent-1":    #84ffff,
  "accent-2":    #18ffff,
  "accent-3":    #00e5ff,
  "accent-4":    #00b8d4
);

$teal: (
  "base":       #009688,
  "lighten-5":  #e0f2f1,
  "lighten-4":  #b2dfdb,
  "lighten-3":  #80cbc4,
  "lighten-2":  #4db6ac,
  "lighten-1":  #26a69a,
  "darken-1":   #00897b,
  "darken-2":   #00796b,
  "darken-3":   #00695c,
  "darken-4":   #004d40,
  "accent-1":    #a7ffeb,
  "accent-2":    #64ffda,
  "accent-3":    #1de9b6,
  "accent-4":    #00bfa5
);

$green: (
  "base":       #4CAF50,
  "lighten-5":  #E8F5E9,
  "lighten-4":  #C8E6C9,
  "lighten-3":  #A5D6A7,
  "lighten-2":  #81C784,
  "lighten-1":  #66BB6A,
  "darken-1":   #43A047,
  "darken-2":   #388E3C,
  "darken-3":   #2E7D32,
  "darken-4":   #1B5E20,
  "accent-1":    #B9F6CA,
  "accent-2":    #69F0AE,
  "accent-3":    #00E676,
  "accent-4":    #00C853
);

$light-green: (
  "base":       #8bc34a,
  "lighten-5":  #f1f8e9,
  "lighten-4":  #dcedc8,
  "lighten-3":  #c5e1a5,
  "lighten-2":  #aed581,
  "lighten-1":  #9ccc65,
  "darken-1":   #7cb342,
  "darken-2":   #689f38,
  "darken-3":   #558b2f,
  "darken-4":   #33691e,
  "accent-1":    #ccff90,
  "accent-2":    #b2ff59,
  "accent-3":    #76ff03,
  "accent-4":    #64dd17
);

$lime: (
  "base":       #cddc39,
  "lighten-5":  #f9fbe7,
  "lighten-4":  #f0f4c3,
  "lighten-3":  #e6ee9c,
  "lighten-2":  #dce775,
  "lighten-1":  #d4e157,
  "darken-1":   #c0ca33,
  "darken-2":   #afb42b,
  "darken-3":   #9e9d24,
  "darken-4":   #827717,
  "accent-1":    #f4ff81,
  "accent-2":    #eeff41,
  "accent-3":    #c6ff00,
  "accent-4":    #aeea00
);

$yellow: (
  "base":       #ffeb3b,
  "lighten-5":  #fffde7,
  "lighten-4":  #fff9c4,
  "lighten-3":  #fff59d,
  "lighten-2":  #fff176,
  "lighten-1":  #ffee58,
  "darken-1":   #fdd835,
  "darken-2":   #fbc02d,
  "darken-3":   #f9a825,
  "darken-4":   #f57f17,
  "accent-1":    #ffff8d,
  "accent-2":    #ffff00,
  "accent-3":    #ffea00,
  "accent-4":    #ffd600
);

$amber: (
  "base":       #ffc107,
  "lighten-5":  #fff8e1,
  "lighten-4":  #ffecb3,
  "lighten-3":  #ffe082,
  "lighten-2":  #ffd54f,
  "lighten-1":  #ffca28,
  "darken-1":   #ffb300,
  "darken-2":   #ffa000,
  "darken-3":   #ff8f00,
  "darken-4":   #ff6f00,
  "accent-1":    #ffe57f,
  "accent-2":    #ffd740,
  "accent-3":    #ffc400,
  "accent-4":    #ffab00
);

$orange: (
  "base":       #ff9800,
  "lighten-5":  #fff3e0,
  "lighten-4":  #ffe0b2,
  "lighten-3":  #ffcc80,
  "lighten-2":  #ffb74d,
  "lighten-1":  #ffa726,
  "darken-1":   #fb8c00,
  "darken-2":   #f57c00,
  "darken-3":   #ef6c00,
  "darken-4":   #e65100,
  "accent-1":    #ffd180,
  "accent-2":    #ffab40,
  "accent-3":    #ff9100,
  "accent-4":    #ff6d00
);

$deep-orange: (
  "base":       #ff5722,
  "lighten-5":  #fbe9e7,
  "lighten-4":  #ffccbc,
  "lighten-3":  #ffab91,
  "lighten-2":  #ff8a65,
  "lighten-1":  #ff7043,
  "darken-1":   #f4511e,
  "darken-2":   #e64a19,
  "darken-3":   #d84315,
  "darken-4":   #bf360c,
  "accent-1":    #ff9e80,
  "accent-2":    #ff6e40,
  "accent-3":    #ff3d00,
  "accent-4":    #dd2c00
);

$brown: (
  "base":       #795548,
  "lighten-5":  #efebe9,
  "lighten-4":  #d7ccc8,
  "lighten-3":  #bcaaa4,
  "lighten-2":  #a1887f,
  "lighten-1":  #8d6e63,
  "darken-1":   #6d4c41,
  "darken-2":   #5d4037,
  "darken-3":   #4e342e,
  "darken-4":   #3e2723
);

$blue-grey: (
  "base":       #607d8b,
  "lighten-5":  #eceff1,
  "lighten-4":  #cfd8dc,
  "lighten-3":  #b0bec5,
  "lighten-2":  #90a4ae,
  "lighten-1":  #78909c,
  "darken-1":   #546e7a,
  "darken-2":   #455a64,
  "darken-3":   #37474f,
  "darken-4":   #263238
);

$grey: (
  "base":       #9e9e9e,
  "lighten-5":  #fafafa,
  "lighten-4":  #f5f5f5,
  "lighten-3":  #eeeeee,
  "lighten-2":  #e0e0e0,
  "lighten-1":  #bdbdbd,
  "darken-1":   #757575,
  "darken-2":   #616161,
  "darken-3":   #424242,
  "darken-4":   #212121
);

$shades: (
  "black":        #000000,
  "white":        #FFFFFF,
  "transparent":  transparent
);


$mtz-colors: (
  "materialize-red": $materialize-red,
  "red": $red,
  "pink": $pink,
  "purple": $purple,
  "deep-purple": $deep-purple,
  "indigo": $indigo,
  "blue": $blue,
  "light-blue": $light-blue,
  "cyan": $cyan,
  "teal": $teal,
  "green": $green,
  "light-green": $light-green,
  "lime": $lime,
  "yellow": $yellow,
  "amber": $amber,
  "orange": $orange,
  "deep-orange": $deep-orange,
  "brown": $brown,
  "blue-grey": $blue-grey,
  "grey": $grey,
  "shades": $shades
) !default;



// Color Classes

@each $color_name, $color in $mtz-colors {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text {
        color: $color_value !important;
      }
    }
    @else if $color_name != "shades" {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}

// Shade classes
@each $color, $color_value in $shades {
  .#{$color} {
    background-color: $color_value !important;
  }
  .#{$color}-text {
    color: $color_value !important;
  }
}


// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function color($color, $type) {
  @if map-has-key($mtz-colors, $color) {
    $curr_color: map-get($mtz-colors, $color);
    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }
  @warn "Unknown `#{$color}` - `#{$type}` in $mtz-colors.";
  @return null;
}

// 1. Colors
// ==========================================================================

$primary-color: color("materialize-red", "lighten-2") !default;
$primary-color-light: lighten($primary, 15%) !default;
$primary-color-dark: darken($primary, 15%) !default;

$secondary-color: color("teal", "lighten-1") !default;
$success-color: color("green", "base") !default;
$error-color: color("red", "base") !default;
$link-color: color("light-blue", "darken-1") !default;


// 2. Badges
// ==========================================================================

$badge-bg-color: $secondary-color !default;
$badge-height: 22px !default;


// 3. Buttons
// ==========================================================================

// Shared styles
$button-border: none !default;
$button-background-focus: lighten($secondary-color, 4%) !default;
$button-font-size: 1rem !default;
$button-icon-font-size: 1.3rem !default;
$button-height: 36px !default;
$button-padding: 0 2rem !default;
$button-radius: 2px !default;

// Disabled styles
$button-disabled-background: #DFDFDF !default;
$button-disabled-color: #9F9F9F !default;

// Raised buttons
$button-raised-background: $secondary-color !default;
$button-raised-background-hover: lighten($button-raised-background, 5%) !default;
$button-raised-color: #fff !default;

// Large buttons
$button-large-icon-font-size: 1.6rem !default;
$button-large-height: $button-height * 1.5 !default;

// Flat buttons
$button-flat-color: #343434 !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Floating buttons
$button-floating-background: $secondary-color !default;
$button-floating-background-hover: $button-floating-background !default;
$button-floating-color: #fff !default;
$button-floating-size: 40px !default;
$button-floating-large-size: 56px !default;
$button-floating-radius: 50% !default;


// 4. Cards
// ==========================================================================

$card-padding: 24px !default;
$card-bg-color: #fff !default;
$card-link-color: color("orange", "accent-2") !default;
$card-link-color-light: lighten($card-link-color, 20%) !default;


// 5. Carousel
// ==========================================================================

$carousel-height: 400px !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;


// 6. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;


// 7. Chips
// ==========================================================================

$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
$chip-margin: 5px !default;


// 8. Date + Time Picker
// ==========================================================================

$datepicker-display-font-size: 2.8rem;
$datepicker-weekday-color: rgba(0, 0, 0, .87) !default;
$datepicker-weekday-bg: darken($secondary-color, 7%) !default;
$datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, .7) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;

$timepicker-clock-color: rgba(0, 0, 0, .87) !default;
$timepicker-clock-plate-bg: #eee;


// 9. Dropdown
// ==========================================================================

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $secondary-color !default;
$dropdown-item-height: 50px !default;


// 10. Fonts
// ==========================================================================

$roboto-font-path: "../fonts/roboto/" !default;


// 11. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 3rem !default;
$input-border-color: color("grey", "base") !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $secondary-color !default;
$input-font-size: 1rem !default;
$input-margin-bottom: 20px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: lighten($input-border-color, 20%) !default;

// Radio Buttons
$radio-fill-color: $secondary-color !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-option-hover: rgba(0,0,0,.06) !default;
$select-option-focus: rgba(0,0,0,.03) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0,0,0,.3) !default;

// Switches
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(lighten($switch-bg-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: rgba(0,0,0,.38) !default;
$switch-radius: 15px !default;


// 12. Global
// ==========================================================================

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;


// 13. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;


// 14. Navigation Bar
// ==========================================================================

$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1rem !default;
$navbar-font-color: #fff !default;
$navbar-brand-font-size: 2.1rem !default;

// 15. Side Navigation
// ==========================================================================

$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0,0,0,.87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: 48px !default;
$sidenav-line-height: $sidenav-item-height !default;


// 16. Photo Slider
// ==========================================================================

$slider-bg-color: color('grey', 'base') !default;
$slider-bg-color-light: color('grey', 'lighten-2') !default;
$slider-indicator-color: color('green', 'base') !default;


// 17. Spinners | Loaders
// ==========================================================================

$spinner-default-color: $secondary-color !default;


// 18. Tabs
// ==========================================================================

$tabs-underline-color: $primary-color-light !default;
$tabs-text-color: $primary-color !default;
$tabs-bg-color: #fff !default;


// 19. Tables
// ==========================================================================

$table-border-color: #d0d0d0 !default;
$table-striped-color: #f2f2f2 !default;


// 20. Toasts
// ==========================================================================

$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;
$toast-action-color: #eeff41;


// 21. Typography
// ==========================================================================

$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1rem !default;


// 22. Footer
// ==========================================================================

$footer-font-color: #fff !default;
$footer-bg-color: $primary-color !default;
$footer-copyright-font-color: rgba(255,255,255,.8) !default;
$footer-copyright-bg-color: rgba(51,51,51,.08) !default;


// 23. Flow Text
// ==========================================================================

$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;


// 24. Collections
// ==========================================================================

$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
$collection-active-bg-color: $secondary-color !default;
$collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;
$collection-link-color: $secondary-color !default;
$collection-line-height: 1.5rem !default;


// 25. Progress Bar
// ==========================================================================

$progress-bar-color: $secondary-color !default;


// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

.no-select {
  user-select: none;
}
