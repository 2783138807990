.favorites-list{
  .badge{
    cursor: pointer;
    font-size: 0.8rem;
    text-align: right;
    position: relative;
    z-index: 10;
  }
  a{
    white-space: nowrap;
  }
}
ul.dropdown-content.favoriteDropdown{
  li{
    span{
      &.badge{
        padding: 0 !important;
        line-height: 22px;
        height: 22px;
        color: #757575;
      }
    }
    a{
      font-size: 1.3rem;
      color: #6f849f;
      text-decoration: none;
      white-space: nowrap;
    }
    p{
      padding: 0 16px;
      span{
        font-size: 1.3rem;
      }
    }
  }
}
