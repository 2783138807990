@include media-breakpoint-down(xs){
  .hidden-xs-down{
    display: none;
  }
  .pd-xs-down-0{
    padding: 0;
  }
  .ml-xs-down-0{
    margin-left: 0;
  }
  .mr-xs-down-0{
    margin-right: 0;
  }
  .footer-row{
    flex-direction: column-reverse;
  }
}


@include media-breakpoint-down(sm){
  .hidden-sm-down{
    display: none !important;
  }

}

@include media-breakpoint-down(md){
  .hidden-md-down{
    display: none !important;
  }
}

@include media-breakpoint-down(lg){
  .hidden-lg-down{
  display: none !important;
  }
}

@include media-breakpoint-up(sm){
  .hidden-sm-up{
    display: none !important;
  }
}
@include media-breakpoint-up(md){
  .hidden-md-up{
    display: none !important;
  }
}
@include media-breakpoint-up(lg) {
  .hidden-lg-up{
    display: none !important;
  }
}
@include media-breakpoint-up(xl){}
