.accordion-container{
  width: 100% !important;
  background-image: none;
  overflow: hidden;
  height: 0;
  margin-top: 0;
  transition:height 0.3s linear, margin-top 0.3s  linear;
  &.open{
    height: 450px;
    margin-top: 30px;
  }
  input:required:valid {
    border-color: green;
  }
  label{
    width: 100%;

  }
}
