.container{
  @include breakpoint(md){
    width: 90%;
    max-width: 1360px;
  }
  @include breakpoint(xl){
   width: 85%;
    max-width: 1360px;
  }
  &-fluid{
    max-width: 1500px;
  }
}
.cards{
  &.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}








