@media screen and (max-width: 991px) {
  .sliderCnt {
    width: 100%;
  }
  section {
    padding: 15px 0 0;

  }

  section .saunaInfo {
    width: auto;
    padding: 10px;
    float: none;
  }

  .saunaInfo .feedbackForm button, .saunaInfo .callbackForm button {
    margin-left: 0px;
  }

}

@media screen and (max-width: 775px) {

  .sliderCnt {
    width: 100%;
  }
  div.ListView {
    width: 700px;
    margin-top: 15px;
    position: relative;
  }
  div.ListView hr {
    display: block;
    bottom: 0px;
  }
  div.bottomView {
    position: absolute;
    bottom: 0px;
    width: 150px;
    display: block;
  }
  .ListView .Action_true {
    left: 110px;
    top: -5px;
  }

  footer .addSauna a,
  footer .addSauna a:link,
  footer .addSauna a:visited,
  footer .addSauna a:active {
    font-size: 14px;
  }
  .banners {
    display: none;
  }
  section .saunaInfo {
    padding: 15px;

  }


  /*.saunaInfo #map, .saunaInfo #map2, .saunaInfo #map3 {*/
  /*width: 100% !important;*/
  /*}*/

  .saunaInfo .feedbackForm .big, .saunaInfo .callbackForm .big {
    float: left;
  }

  .saunaInfo .feedbackForm button, .saunaInfo .callbackForm button {
    margin-left: 0px;
  }


}

@media screen and (max-width: 667px) and (orientation: landscape) {


  section .saunaInfo {
    padding: 1%;

  }



  /*.saunaInfo #map, .saunaInfo #map2, .saunaInfo #map3 {*/
  /*width: 100%;*/
  /*}*/

  .saunaInfo .feedbackForm .big, .saunaInfo .callbackForm .big {
    float: left;
  }

  .saunaInfo .feedbackForm button, .saunaInfo .callbackForm button {
    margin-left: 0px;
  }

  .saunaInfo .feedbackForm .big, .saunaInfo .feedbackForm textarea {
    width: 270px;
    max-width: 270px;
  }

  .priceTable {
    width: 98% !important;
    max-width: 98% !important;
  }

}

@media screen and (max-width: 667px) and (orientation: portrait) {





  .saunaInfo .ratingContainer {
    margin-top: -10px;
  }

  /*.saunaInfo #map, .saunaInfo #map2, .saunaInfo #map3 {*/
  /*width: 100% !important;*/
  /*}*/

  .saunaInfo .feedbackForm .big, .saunaInfo .callbackForm .big {
    float: left;
  }

  .saunaInfo .feedbackForm button, .saunaInfo .callbackForm button {
    margin-left: 0px;
  }

  .saunaInfo .photos .small {
    width: 100%;
    position: relative;
    left: 3%;
    top: 2%;
  }

  .saunaInfo .photos .big {
    width: 100%;
  }

  .saunaInfo .feedbackForm .big, .saunaInfo .feedbackForm textarea {
    width: 270px;
    max-width: 270px;
  }

  .priceTable {
    width: 98% !important;
  }

  div.city {
    display: none;
  }


  footer .contacts {
    width: 100%;
  }
}
@media screen and (max-width: 640px)  {
  .banner-container .banner:nth-child(2) {
    display: none;
  }
}
