.mainButton{
  color: #eee;
  background: rgb(82,104,122); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(82,104,122,1) 1%, rgba(106,135,157,1) 18%, rgba(92,118,139,1) 56%, rgba(80,105,125,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(82,104,122,1) 1%,rgba(106,135,157,1) 18%,rgba(92,118,139,1) 56%,rgba(80,105,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(82,104,122,1) 1%,rgba(106,135,157,1) 18%,rgba(92,118,139,1) 56%,rgba(80,105,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#52687a', endColorstr='#50697d',GradientType=0 ); /* IE6-9 */
}

.button{
  &-green{
    background-color: #8fd771;
    color: #fff;
    font-size: 18px;
    &:hover{
      text-decoration: none;
      color: #fff;
      background-color: #64bc3f;
    }
  }
  &-orange{
    background-color: $base-orange;
    color: #fff;
    font-size: 18px;
    &:hover{
      text-decoration: none;
      color: #fff;
      background-color: #da6914;
    }
  }
  &-yellow{
    background-color: #ffc619;
    color: #fff;
    font-size: 18px;
    &:hover{
      text-decoration: none;
      color: #fff;
      background-color: #d6a616;
    }
  }
  &-blue{
    background-color: $link;
    color: #fff;
    font-size: 18px;
    &:hover{
      text-decoration: none;
      color: #fff;
      background-color: #284678;
    }
  }
}


