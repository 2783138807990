.chips{
  border-bottom: none;
  min-height: 0;
  .chip{
    cursor: pointer;
    border-radius: 3px;
    cursor: pointer;
    &:hover{
      @extend .blue-grey.lighten-3;
      text-decoration: none;
    }
  }
  button{
    border: none;
    &:focus{
      background-color: #668398;
      color: #ced4da;
    }
  }

}


