@mixin breakpoint($point) {
  @if $point == sxl {
    @media (min-width: 1300px) { @content ; }
  }
  @if $point == xl {
    @media (min-width: 1200px) { @content ; }
  }
  @else if $point == lg {
    @media (min-width: 960px) { @content ; }
  }
  @else if $point == md {
    @media (min-width: 720px) { @content ; }
  }
  @else if $point == sm {
    @media (min-width: 540px)  { @content ; }
  }
  @else if $point == xsm {
    @media (min-width: 450px)  { @content ; }

  }
}



