.tabs{
  list-style: none;
  .tab{
    a.chip{
      min-width: 70px;
      text-align: center;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      height: auto;
      line-height: 22px;
      &:hover{
        text-decoration: none;
        color: rgba(0,0,0,.6);
      }
      &.active{
        @extend .grey;
        color: #fff;
      }
    }
  }
  .indicator{
    background-color: transparent;
  }
}
