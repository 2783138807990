  ul.list-link:not(.dropdown-content){

    list-style: none;
    li{
      position: relative;
      padding: 0 10px;
      font-size: 16px;
      &:after{
        content: '';
        width: 2px;
        height: 16px;
        position: absolute;
        background-color: #505050;
        display: inline-block;
        top: 50%;
        right: -1px;
        margin-top: -8px;
      }
      &:last-child, &:first-child{
        &:after{
          display: none;
        }
      }
      &:first-child{
        padding-left: 0;
        white-space: nowrap;
        width: 100%;
        text-align: center;
        @include breakpoint(sm) {
          width: auto;
          text-align: left;
        }
      }
      &:nth-child(2){
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
      a{
        font-size: 16px;
        text-transform: none;
        color: $link;
        overflow: auto;
        padding: 0 2%;

        &:hover{
          text-decoration: none;
        }
        &.active {
          color: $link-active;
          &:hover{
            color: $link-active;
          }
        }
      }
    }
  }

