.btn{
  &.btn-flat {
    box-shadow: none !important;
    background-color: transparent !important;
    color: inherit!important;
    background-image: none;
    border: none;
  }
}
.buttons{
  .fa{
    &:before{
      font-size: 1rem;
    }
  }
}
