.saunaInfo{
  padding: 25px;
  margin-bottom: 15px;

  background-color: #fff;
  -webkit-box-shadow: 0px 0px 6px #ccc, 0px 0px 6px #ccc, 0px 0px 6px #ccc;
  -moz-box-shadow: 0px 0px 6px #ccc, 0px 0px 6px #ccc, 0px 0px 6px #ccc;
  box-shadow: 0px 0px 6px #ccc, 0px 0px 6px #ccc, 0px 0px 6px #ccc;
  h2 {
    color: $link;
    font-size: 18px;
    font-weight: 700;
  }


  .data {
    .parametr {
      font-size: 12px;
      color: #757575;
    }
    .small {
      font-size: 10px;
      color: #757575;
      line-height: 12px;
    }
    .tel {
      font-size: 16px;
      font-weight: bold;
      color: #97ab38;
    }
    .price {
      font-weight: bold;
    }
    .numberLink {
      color: #426ea4;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      border-bottom: 1px dotted #426ea4;
    }
  }
  #map {
    width: 99.5%;
    height: 300px;
    border: 2px solid #cfcfcf;
  }
  .feedbackSection{
    ul {
      margin-left: 0;
      padding-left: 0;

      .card{
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        position: relative;
        &:before{
          content: '';
          position: relative;
          width: 0; height: 0;
          border-top: 5px solid transparent;
          border-right: 10px solid;
          border-bottom: 5px solid transparent;
          position: absolute;
          left: -10px;
          top: 30px;
        }
      }
      &>li{
        .card{
         @extend  .blue-grey, .lighten-5;
          &:before{
            border-right-color: #eceff1;
          }
        }
        &>ul{
          li{
            .card{
             @extend .blue-grey, .lighten-4;
              margin: 0 6%;
              &:before{
                border-right-color: #cfd8dc;
              }
            }
          }
        }
      }
    }
  }
  .number{
    h3 {
      font-size: 18px;
    }
    .parametr {
      font-size: 12px;
      color: #828282;
    }
    .price {
      font-size: 14px;
      font-weight: bold;
    }
  }
  &.simpleText{
    img{
      width: 100%;
      height: auto;
      @include breakpoint(md) {
        width: auto;
      }
    }

  }
}
.services{
  margin-bottom: 15px;
  p{
    padding: 4px 0;
    margin-bottom: 0;
    color: $base-orange;
    &:nth-child(even){
    color: $base-font-color;
    }
  }
}
.mob-buttons{
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 -15px;
  z-index: 10;
}
