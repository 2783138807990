.searchForm {
  position: relative;
  flex-grow: 1;
  padding-right: 0;
  flex-wrap: wrap;
  min-height: 120px;

  @include breakpoint(xsm) {
    flex-direction: row;
    padding-bottom: 10px;
  }
  @include breakpoint(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 0;
  }
  @include breakpoint(xl) {
    margin-top: 0;
  }


  .label {
    font-size: 12px;
    color: #bdd354;

    .field {
      font-size: $font-size-big-1;

      @media (min-width: 1200px){
        font-size: 14px;
      }
      @media (min-width: 1300px){
        font-size: $font-size-big-1;
      }
      color: #73a6e4;
      border-bottom: 1px dotted #73a6e4;
      cursor: pointer;
      overflow: hidden;
      max-width: 200px;
    }
    .pay {
      color: #73A6E4;
      font-size: 16px;
    }
  }

  .searchCont {
    margin: 0 -1.5rem;
    flex-grow: 1;
    position: absolute;
    @include breakpoint(xsm) {
      justify-content: space-around;
      margin: 0;
    }
    left: 0;
    right: 0;
    bottom: -28px;
    display: flex;
    justify-content: space-between;
    @include breakpoint(md) {
      justify-content: space-between;
    }

    .topButtons {
      @extend .mainButton;
      border: none;
      cursor: pointer;
      position: relative;
      border-radius: 0 0 5px 5px;
      line-height: 25px;
      padding: 2px 10px;
      text-align: center;
      box-shadow: -1px 6px 8px -3px rgba(0,0,0,0.2);
      font-size: 0.8rem;

      &:hover {
        color: #ccc;
      }
      &:focus{
        outline: none;
      }
    }
  }
}

