

ins {
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}





pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}



ol {
  list-style-type: decimal;
}

nav ul, nav li {
  margin: 0;
}


strong, th {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

pre {
  padding: 15px;
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

textarea {
  overflow: auto;
}

.ie6 legend, .ie7 legend {
  margin-left: -7px;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}



label, input[type=button], input[type=submit], button {
  cursor: pointer;
}

button, input, select, textarea {
  margin: 0;
}

input:valid, textarea:valid {
}

.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
  background-color: #f0dddd;
}






.ir {
  display: block;
  text-indent: -999em;
  overflow: hidden;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visuallyhidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.invisible {
  visibility: hidden;
}

