footer {
  background: rgb(45,52,61); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(45,52,61,1) 0%, rgba(60,70,82,1) 1%, rgba(58,68,80,1) 30%, rgba(36,43,51,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(45,52,61,1) 0%,rgba(60,70,82,1) 1%,rgba(58,68,80,1) 30%,rgba(36,43,51,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(45,52,61,1) 0%,rgba(60,70,82,1) 1%,rgba(58,68,80,1) 30%,rgba(36,43,51,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d343d', endColorstr='#242b33',GradientType=0 ); /* IE6-9 */
  min-height: 250px;
  width: 100%;
  color: #eee;
  position: relative;
  .city {
    column-count: 4;
    a, a:active, a:link, a:visited {
      color: #eee;
      font-size: 12px;
      line-height: 18px;
      &:hover{
        color: #f60;
      }
    }
  }
  .feedback {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 0 30px;
    &:before, &:after{
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      display: block;
      background-color: rgba(#cb8c5e, 0.4);
      width: 1px;
    }
    &:before{
      left: 0;
      display: none;
      @include breakpoint(lg) {
        display: block;
      }
    }
    &:after{
      right: -15px;
      display: none;
      @include breakpoint(lg) {
        right: 0;
      }
      @include breakpoint(md) {
        display: block;
      }
    }

    textarea {
      max-height: 135px;
      max-width: 100%;
    }
    .buttonContainer {
      text-align: center;
      margin-top: 10px;
    }
    span {
      display: block;
      font-size: 12px;
      margin-bottom: 10px;
      text-align: center;
    }


    .title {
      width: 55px;
      height: 0px;
      position: relative;
      margin-left: -70px;
    }

    }
  .addSauna {
    position: relative;
    width: 200px;
    border-top-color: #c27f1f;
    height: 38px;
    text-align: center;
    margin-top: -14px;
    left: 50%;
    margin-left: -100px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.34), 0px -1px 0px 0px #b3761c;
    background: rgb(224,147,35); /* Old browsers */
    background: -moz-linear-gradient(top, rgba(224,147,35,1) 1%, rgba(231,126,22,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(224,147,35,1) 1%,rgba(231,126,22,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(224,147,35,1) 1%,rgba(231,126,22,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e09323', endColorstr='#e77e16',GradientType=0 );
    a, a:link, a:visited, a:active{
      color: #fff;
      line-height: 32px;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
      &:hover{
        color: #3c4551;
      }
    }
  }
  .contacts{
    padding: 0 20px;


    span {
      display: block;
      font-size: 11px;

    }
    .copyright {
      margin-top: 38px;
    }
    .material {
      margin-top: 10px;
      a{
        color: #fff;
      }
    }
    .cooperation {
      margin-top: 55px;
    }
    .phone {
      margin-top: 10px;
    }
    .phone, .mail{
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
    }
    .mail{
      a, a:link, a:visited, a:active{
        color: #fff;
        &:hover{
          color: #f60;
        }
      }
    }
  }
  .container-fluid{
    padding-top: 15px;
    padding-bottom: 18px;
  }
  .slBlock{
   justify-content: flex-start;
  }
}
