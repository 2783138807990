a{
  color: $link;
}
p{
  font-size: $base-font-size;
}
h4, h5{
  color: #50697d;
  &.title{
    font-size: 18px;
    @include breakpoint(sm) {
      font-size: 21px;
    }
  }
}
.font-size-34{
  font-size: 34px !important;
}
.font-size-11{
  font-size: 11px !important;
}
.font-size-13{
  font-size: 13px !important;
}

.text{
  &-base-green{
    color: $base-green  !important;
  }
  &-big{
    font-size: 18px !important;
  }
  &-base-orange{
    color: $base-orange  !important;
  }
  &-base-blue{
    color: $link  !important;
  }
  &-base{
    color: $base-font-color !important;
  }
  &-size-big-1{
    font-size: $font-size-big-1 !important;
    h1{
      font-size: $font-size-big-1 !important;
    }
  }
}

.bg-color{
  &-red{
    background-color: red  !important;

  }
}

.parametr {
  color: $link;
  font-weight: 700;
}
.parametrs{
  p>span{
    color: $link;
    font-weight: 700;
  }
}
.location{

  white-space: nowrap;
    color: $base-blue;
    border-bottom: 1px dashed $base-blue;
}
