.map{
  //@include sprite($map);
  background-image: url('/site/images/map.png');
  -webkit-background-size: cover;
  background-size: cover;
  &-wrapper{
    height: 70vh;
  }
}

#map{
  background: #fff;
  z-index: 10;
  position: relative;
  overflow: hidden;
}

.maps-set-field{
  button{
    &.active{
      @extend .light-green, .darken-2;
    }
  }
}
ymaps, .contentString{
  h3{
    font-size: 20px;
  }
}

