.sliderCnt{
  margin-top: 12px;
  .slider{

    &-track{
      background-color: transparent ;
      background-image: none;
      height: 3px !important;
      background: rgb(157,213,58); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(157,213,58,1) 0%, rgba(161,213,79,1) 50%, rgba(128,194,23,1) 51%, rgba(124,188,10,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(157,213,58,1) 0%,rgba(161,213,79,1) 50%,rgba(128,194,23,1) 51%,rgba(124,188,10,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(157,213,58,1) 0%,rgba(161,213,79,1) 50%,rgba(128,194,23,1) 51%,rgba(124,188,10,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9dd53a', endColorstr='#7cbc0a',GradientType=0 ); /* IE6-9 */
    }
    &-selection{
      background: rgb(157,213,58); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(157,213,58,1) 0%, rgba(161,213,79,1) 50%, rgba(128,194,23,1) 51%, rgba(124,188,10,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(157,213,58,1) 0%,rgba(161,213,79,1) 50%,rgba(128,194,23,1) 51%,rgba(124,188,10,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(157,213,58,1) 0%,rgba(161,213,79,1) 50%,rgba(128,194,23,1) 51%,rgba(124,188,10,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9dd53a', endColorstr='#7cbc0a',GradientType=0 ); /* IE6-9 */
    }
    &-handle{
      top: -3px;
    }
    &.slider-horizontal{
      display: flex;
      flex-grow: 1;
      margin: 0 1%;
      width: auto;
    }
  }
  .tooltip-inner{
   color:  #bdd354;
  }
  .tooltip{
    display: flex;
    flex-grow: 1;
    justify-content:  center;
    &.top{
      margin-top: -26px;
      &.tooltip-min, &.tooltip-max{
       display: none !important;
      }

    }
    &.bottom{
      display: none !important;
    }
  }

  &:before, &:after{
    color:  #bdd354;
    position: relative;
    top: -3px;
  }
  &:before{
    content: attr(data-min) ;
    margin-right: 10px;

  }
  &:after{
    content: attr(data-max) ;
    margin-left: 10px;
  }
}
#slide-out{
  .sliderCnt{
    .tooltip{
      justify-content:  flex-end;
      &.top{
        &:before{
          content: 'Цена за час:';
          position: absolute;
          color:  #bdd354;
          left: -5px;
        }
      }
    }
  }
}




