.input-field{
  margin-top: 0.5rem;
  input{
    &.brown-border{
      border-bottom-color: #cb8c5e;
    }
    &.autocomplete{
      color: #73a6e4;
    }
  }

}
.modal-content{
  .input-field{
    label{
      @extend .grey-text, .text-darken-1;
    }
    &.active{
      color: #558b2f;
    }
  }
}

ul.autocomplete-content{
  &.dropdown-content{
    position: absolute;
    list-style: none;
    padding-left: 0;
    left: 0;
    right: 0;
    margin-top: 0;
    li{
      line-height: 15px;
    }
  }


}
.text{
  &-no-transform{
    text-transform: none !important;
  }
}
.grey-theme{
  input, textarea{
    color: #fff;
    font-size: 11px;

  }
  .input-field input[type=text]:focus + label,
  .input-field input[type=email]:focus + label,
  .input-field input[type=tel]:focus + label,
  .input-field input[type=number]:focus + label,
  .input-field textarea:focus + label
  {
    color: #eee;
  }
}

.green-theme{
  .input-field input[type=text]:focus + label,
  .input-field input[type=email]:focus + label,
  .input-field input[type=tel]:focus + label,
  .input-field input[type=number]:focus + label,
  .input-field textarea:focus + label
  {
    color: #558b2f;
  }
  .input-field input[type=text]:focus,
  .input-field input[type=email]:focus,
  .input-field input[type=tel]:focus,
  .input-field input[type=number]:focus,
  .input-field textarea:focus

  {
    border-bottom: 1px solid #558b2f;
    box-shadow: 0 1px 0 0 #000;
  }
  .prefix{
    &.active{
      color: #73a6e4;
    }
  }

}


.input-field{
  .helper-text{
    position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,0.54);
  }
  input:not([type]){
    &.invalid ~ .helper-text:after {
        content: attr(data-error);
        color: #F44336;

    }
    &.valid ~ .helper-text:after {
        content: attr(data-success);
        color: #4CAF50;
      }
  }
}
.addNewSauna{
    label{
      color: #3c4652;
  }
}
.select-dropdown{
  list-style: none;
  padding-left: 0;
}
