.text {
  color: #50697d;
    img {
      margin-top: 5px;
      max-width: 100%;
      border: 2px solid #ccc;
    }
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 12px;
    }
    p {
      font-size: 13px;
      &.small{
        font-size: 11px;
      }
    }
  &-orange{
    color: $orange;
  }
}
