
.modal-content{
  background: rgb(36,43,51); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(36,43,51,1) 0%, rgba(60,70,82,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(36,43,51,1) 0%,rgba(60,70,82,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(36,43,51,1) 0%,rgba(60,70,82,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#242b33', endColorstr='#3c4652',GradientType=0 ); /* IE6-9 */
  position: relative;
  .sliderCnt{
    position: relative;
    padding-top: 10px;
    .slider{
      width: 100%;
    }
    &:before, &:after{
      position: absolute;
      top: -20px;
    }
    &:after{
      right: 0;
    }
    &:before{
      left: 0;
    }
  }
  h5.phone-link{
    font-size: 1.5rem;
    @include breakpoint(sm) {
      font-size: 1.64rem;
    }
  }
  [type=checkbox]:checked+label:before{
    width: 8px;
    height: 15px;
  }
}
.modal-close{
  z-index: 40;
  position: relative;
}
.modal{
  &-content{
    background: #fff;
    .sucs{
      [type=checkbox]+label{
        height: auto;
        padding-left: 25px;
        font-size: 13px;
        line-height: 16px;
        color: #212529;
      }
    }

  }
  button[type="submit"] {
    width: 100%;
    @include breakpoint(sm) {
      width: auto;
    }
  }
  &-overlay{
    opacity: 0.8 !important;
  }
}
@media only screen and (max-width: 550px){
  .modal {
    width: 90% !important;
  }
}

