$base-font : 'Open Sans', sans-serif;
$base-font-size : 14px;
$base-font-color : #000;
$base-blue : #5f92d8;
$font-size-big-1 : 16px;
$link : #3a66b0;
$base-orange : #ff7c19;
$base-green : #94c11a;
$base-border : #a7a7a7;
$link-active : #818181;



