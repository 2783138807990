.default-page-carousel{
  @include breakpoint(sm) {
    padding: 0 60px;
  }
  button{
    width: 40px;
    height: 40px;
    &.slick-prev{
      left: 0;
    }
    &.slick-next{
      right: 0;
    }
    &:before{
      font-size: 40px;
     color: rgba(#546e7a, .8);
    }
  }
  &.responses{
    .card{
      min-height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .time{
        color: #ffab40;
      }
    }
    .slick-slide{
      padding: 5px 10px;
      display: flex;
      justify-content: center;
      .description{
        height: 242px;
        max-height: 242px;
        overflow: hidden;
        p{
          word-wrap: break-word;
        }
      }
      .card-title{
       &.top{
         height: 80px;
         max-height: 80px;
       }
        h6{
          font-size: 0.75em;
        }
      }
      &>div{
        width: 250px;
        @include breakpoint(xl) {
       //   width: 100%;
        }
      }
    }
  }
}
.photos{
  .slider-for{
    img{
      width: 100%;
    }
  }
  .slider-nav{
    .slick-slide{
      &>div{
        margin: 0 3px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    button.slick-next{
      right: 20px;
    }
    button.slick-prev{
      left: 10px;
    }
    button.slick-arrow{
      z-index: 10;
      top: 40%;
      &:before {
        font-size: 30px;
      }
    }
   }
}
.inner-page-carousel{
     .banner{
       text-align: center;
       p{
         display: inline-block;
       }
     }
}

.up-banner-page-carousel{
  .banner{
    text-align: center;
    p{
      display: inline-block;
    }
  }
}

.down-banner-page-carousel{
  .banner{
    text-align: center;
    p{
      display: inline-block;
    }
  }
}

.slider-for{
  max-height: 450px;
  overflow: hidden;
  margin-bottom: 20px;
}
.slick-track{
  display: flex;
}

