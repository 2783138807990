.autocomplete-extra{
  .autocomplete-suggestion{
    cursor: pointer;
    line-height: 32px;
    font-size: 16px;
  }
}
.autocomplete-extra-long{
  width: 305px !important;
  box-shadow: none;
  .autocomplete-suggestion{
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
  }
}
.search-form{
  position: relative;
  .header-autocomplete{
    background-color: #b6c1c9;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    height: 26px;
    bottom: -5px;
    &:focus{
      outline: none;
    }
  }
  .fa{
    position: absolute;
    top: 11px;
    left: 5px;
    z-index: 5;
  }
}
