.navigation_saun{
  position: fixed;
  top: 50%;
  margin-top: -81px;
  max-height: 162px;
  min-height: 75px;
  height: 30%;
  width: 40px;
  z-index: 900;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  .saun_link{
    background-repeat: no-repeat;
    display: block;
  }

  #next_link{
    @include sprite($ears-next1-mob);
    &:hover{
      @include sprite($ears-next2-mob);
    }
  }
  #prev_link{
    @include sprite($ears-prev1-mob);
    &:hover{
      @include sprite($ears-prev2-mob);
    }
  }
  &.next_saun {
    right: 0px;
  }
  &.prev_saun {
    left: 0px;
  }
}



