.vcard{
 // width: 290px;
  //min-width: 250px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s linear;

  @include breakpoint(sm) {
    width: 50%;
    min-width: 0;
  }
  @include breakpoint(md) {
    width: 33%;
  }
  @include breakpoint(lg) {
    width: 25%;
  }
  @include breakpoint(xl) {
    width: 20%
  }
  @media (min-width: 1400px)  {
    width: 16.6%
  }

  padding: 0 8px;

  .thumb-item {

    overflow: hidden;
  }
  &.connect{
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}


.saunaCard {
  width: 100%;
  position: relative;

  background-color: #fff;
  border: 1px solid #c9c9c9;
  -webkit-box-shadow: 0px 0px 6px #d8d8d8, 0px 0px 6px #d8d8d8;
  -moz-box-shadow: 0px 0px 6px #d8d8d8, 0px 0px 6px #d8d8d8;
  box-shadow: 0px 0px 6px #d8d8d8, 0px 0px 6px #d8d8d8;
  cursor: pointer;
  &:hover{
    border: 1px solid #ccd7ac;
    -webkit-box-shadow: 0px 0px 6px #ccd7ac, 0px 0px 6px #ccd7ac, 0px 0px 6px #ccd7ac;
    -moz-box-shadow: 0px 0px 6px #ccd7ac, 0px 0px 6px #ccd7ac, 0px 0px 6px #ccd7ac;
    box-shadow: 0px 0px 6px #ccd7ac, 0px 0px 6px #ccd7ac, 0px 0px 6px #ccd7ac;
  }

  .category {
    font-size: $base-font-size;
    font-weight: 400;

  }
  .card-content{
    h3{
      font-size: 1.05rem;
      margin: 0 0 12px;
    }
    a.fn {
      font-weight: 700;
      font-size: 17px;
      color: $base-orange;
      text-decoration: none;

   }
  }
  img{
    width: 100%;
    height: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    @include breakpoint(sm){
      height: 140px;
    }
    &[data-lazy]{
      height: 0;
    }
  }

  .tel {
    font-size: 16px;
    font-weight: bold;
    color: #87973b;
    display: block;
    margin-bottom: 9px;
  }
  .price {
    color: #87973b;
  }

  .rating {
    @include sprite($like);
    text-align: center;
    line-height: 29px;
    color: #000;
  }

  .comments {
    @include sprite($comments);
    color: #000;
    line-height: 26px;
    text-align: center;
  }
  .navigation {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 14px;
    margin-top: 20px;
    height: 20px;
    font-size: 15px;
    color: #50697D;
    text-align: left;
  }
  &__footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.card{
    .card-content{
      padding: 10px;
    }

  }
}

.ListView{

  .photos{
    img{
      height: 200px;
      max-height: 200px;

    }
  }
}


